<template>
  <VCard>
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm
        ref="templateForm"
      >
        <VContainer fluid>
          <VRow>
            <VCol>
              <VAlert
                colored-border
                border="left"
                type="info"
                elevation="1"
              >
                <ul>
                  <li>Все поля обязательны к заполнению</li>
                  <li>Если изменить имя существующего шаблона, при сохранении будет создан новый</li>
                  <li>
                    Имя шаблона должно содержать только символы
                    <span class="font-weight-bold tt-light-red--text">a-z</span> ,
                    <span class="font-weight-bold tt-light-red--text">0-9</span> ,
                    <span class="font-weight-bold tt-light-red--text">.</span> ,
                    <span class="font-weight-bold tt-light-red--text">/</span> ,
                    <span class="font-weight-bold tt-light-red--text">-</span> (только нижний регистр символов,
                    первый символ - обязательно буква)
                  </li>
                </ul>
              </VAlert>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :rules="rules"
                :value="entity.name"
                label="Имя шаблона"
                name="templateName"
                placeholder="template"
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :rules="rules"
                :value="entity.subject"
                label="Тема письма"
                placeholder="subject"
                name="templateSubject"
                @input="$emit('update:subject', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                :rules="rules"
                :value="entity.htmlTemplate"
                label="HTML разметка"
                outlined
                @input="$emit('update:htmlTemplate', $event)"
              />

              <div
                class="d-flex justify-end mt-n5"
              >
                <TTTooltip left>
                  <template #activator="{ on, attrs }">
                    <VBtn
                      color="tt-secondary"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="handleShowAsHTML"
                    >
                      <VIcon
                        class="mr-2"
                        small
                      >
                        fal fa-eye
                      </VIcon>
                      Просмотр
                    </VBtn>
                  </template>
                  <span>Просмотр шаблона в виде HTML в отдельном окне</span>
                </TTTooltip>
              </div>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                :rules="rules"
                :value="entity.textTemplate"
                label="Текстовая разметка"
                outlined
                @input="$emit('update:textTemplate', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { DEFAULT_TEMPLATE } from './common';

export default {
  name: 'TemplateForm',

  props: {
    entity: {
      type: Object,
      default: () => ({ ...DEFAULT_TEMPLATE }),
    },
  },

  data() {
    return {
      valid: true,
      rules: [
        (v) => !!v || 'Это поле обязательно',
      ],
    };
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание шаблона';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование шаблона';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      if (this.$refs.templateForm.validate()) {
        this.$emit('submit', this.entity);
      }
    },
    handleShowAsHTML() {
      const newTab = window.open('', '_blank');
      if (newTab) {
        newTab.document.write(this.entity.htmlTemplate);
        newTab.document.close();
      } else {
        this.$di.notify.errorHandler(new Error('Произошла ошибка обратитесь к администратору'));
      }
    },
  },
};
</script>
